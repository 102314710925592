import 'bootstrap-sass'

const context = function() {

  const mybutton = document.getElementById("myBtn");

  window.onscroll = function() {scrollFunction()};

  function scrollFunction() {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      mybutton.style.display = "block";
    } else {
      mybutton.style.display = "none";
    }
  }

  mybutton.addEventListener('click', function(e) {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  })
}

$(document).ready(context)
